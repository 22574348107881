import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Advaita Vedanta",
  "date": "2019-02-01T00:00:00.000Z"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`All hail to Lord Adi Shankaracharya, who is the beloved and most praised divine soul of our Era. TBH the only reason for being a proud Keralite is being a neighbor of Shankaracharya. My hometown is approx 100 kilometers from Kalady, which is the birthplace of Shankara. `}</p>
    <p>{`My seeking for the ultimate truth finds its way towards Advaita Vedanta. This was the only doctrine that cleared all my concerns and doubts. Hence I believe everyone traveling towards self-realization will surf through Non- Dualistic realities to experience the supreme reality. `}</p>
    <p>{`The paradox of Advaita as Shankara said is "One has to go through the Dualistic Nature, To understand Non-Dulatity", Means Advaita can be realized only after experiencing the Dvaitic Nature. One cannot absorb the Advaitic Truths if he is not exposed to the Dvatic Reality. `}</p>
    <p>{`Shankra mentioned this on numerous occasions when having a debate about Mimasa and Buddhist traditions. As long as one
goes in deep towards Advaita, He will identify it as the same as Buddhist 'Shunyatha' or 'Void'.  Buddist traditions of Mahayana and Theravada strictly follow Formless/Matterless Source also they point towards the fact of vast nothingness or emptiness. `}</p>
    <p>{`However Advaita too teaches the same, the supreme Para Brahma is "Void", 'Nothing exists there, yet Everything exists'.
Unmanifested gets manifested. In every individual there exist the divine spirit Energy of the Para Brahma. This is called Prana, Spirit Energy etc. `}</p>
    <p>{`Life manifests from this spirit energy, and it flows continuously in a perpetual cycle. In both Advaitic and Buddhist traditions, there is no difference between, 'Doer', 'Doing', and 'Object'. Everything is One, There is no boundary between anything, Only pure consciousness exists.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      